<template>
  <div class="users" v-loading="loading">
    <el-row type="flex" justify="space-between" align="center">
      <el-col>
        <el-page-header content="Акты" title="на главную" @back="$router.push('/')"></el-page-header>
      </el-col>
    </el-row>

    <el-divider></el-divider>
    <el-form>
      <el-form-item label="Период">
        <el-date-picker v-model="period" type="datetimerange" unlink-panels
          @change="$store.state.reload = true; getUsersSum($event)">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="Дата выставления счета">
        <el-date-picker v-model="actDate" type="date" unlink-panels>
        </el-date-picker>
      </el-form-item>
      <el-form-item label="Дата 1">
        <el-date-picker v-model="actDate1" type="date" unlink-panels>
        </el-date-picker>
      </el-form-item>
      <el-form-item label="Дата 2">
        <el-date-picker v-model="actDate2" type="date" unlink-panels>
        </el-date-picker>
      </el-form-item>
    </el-form>
    <el-tabs type="card">
      <el-tab-pane v-for="(item, index) of tabs" :key="index" :label="item.label">
        <el-table :loading="loading" :data="filteredUsers(index)" class="usersTable" :ref="'table' + index"
          @selection-change="select">
          <el-table-column type="selection"></el-table-column>
          <el-table-column label="ФИО" prop="name"></el-table-column>
          <el-table-column label="Сумма" prop="bank">
            <template #default="scope">
              <span v-if="scope.row.bank">
                <el-input style="max-width: 150px;" v-model="scope.row.bank"></el-input>
              </span>
            </template>
          </el-table-column>

          <el-table-column width="170px" prop="sum">
            <template slot-scope="scope">
              <el-button :loading="saving || !users_sum"
                @click="createActFromBank({ users: [scope.row], period, actDate, actDate1, actDate2 })" size="small"
                type="primary">Сформировать акт</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>

    <el-row style="max-width: 330px; margin-top: 20px;">
      <el-col :span="12">
        <el-button :loading="saving" size="small" type="primary"
          @click="createActFromBank({ users: $store.state.s, period, actDate, actDate1, actDate2 })">Сформировать
          акт</el-button>
      </el-col>
    </el-row>

    <el-dialog title="Добавление сотрудника" :visible.sync="dialog" width="30%">
      <el-form>
        <el-row type="flex" justify="between">
          <el-col :span="11">
            <el-form-item label="ФИО">
              <el-input v-model="add.fio"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="1" :offset="1"></el-col>
          <el-col :span="11">
            <el-form-item label="Логин">
              <el-input v-model="login"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row type="flex" justify="between">
          <el-col :span="11">
            <el-form-item label="Пароль">
              <el-input v-model="add.password"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="1" :offset="1"></el-col>
          <el-col :span="11">
            <el-form-item label="Bitrix ID">
              <el-input v-model="add.bxid"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="Группа">
          <el-select style="width: 100%" v-model="add.group">
            <el-option label="Логист" value="0"></el-option>
            <el-option label="Оператор" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Реквизиты">
          <el-input type="textarea" v-model="add.rq"></el-input>
        </el-form-item>
        <el-form-item label="Подпись">
          <el-upload :on-success="fileUploaded" style="width: 100%" action="https://docs.rosperevozki.ru/api/upload" drag>
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
          </el-upload>
        </el-form-item>
        <el-form-item>
          <el-button type="success" @click="addUser({ login, ...add }); dialog = false;">Добавить</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>


    <el-dialog title="Редактирование сотрудника" :visible.sync="dialogEdit" width="30%">
      <el-form>
        <el-row type="flex" justify="between">
          <el-col :span="11">
            <el-form-item label="ФИО">
              <el-input v-model="edit.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="1" :offset="1"></el-col>
          <el-col :span="11">
            <el-form-item label="Логин">
              <el-input v-model="edit.login"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row type="flex" justify="between">
          <el-col :span="11">
            <el-form-item label="Пароль">
              <el-input v-model="add.password"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="1" :offset="1"></el-col>
          <el-col :span="11">
            <el-form-item label="Bitrix ID">
              <el-input v-model="edit.bxid"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="Группа">
          <el-select style="width: 100%" v-model="edit.group_id">
            <el-option label="Логист" value="0"></el-option>
            <el-option label="Оператор" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Реквизиты">
          <el-input type="textarea" v-model="edit.rq"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="success" @click="userEditStore({ ...edit }); dialogEdit = false;">Обновить</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, state } from "vuex";
import moment from "moment";
import { transliterate as tr, slugify } from "transliteration";
import pgen from "password-gen";
export default {
  data() {
    return {
      actDate: moment(),
      actDate1: moment(),
      actDate2: moment(),

      dialog: false,
      dialogEdit: false,
      tabs: [
        {
          label: "Логисты",
        },
        {
          label: "Операторы",
        },
      ],
      period: [moment().subtract("1", "month"), moment()],
      selectedUsers: [],
      add: {
        fio: null,
        password: pgen(10),
        group: null,
        bxid: null,
        rq: null,
        file: null
      },
      edit: {
        name: null,
        login: null,
        bxid: null,
        group_id: null,
        rq: null
      }
    };
  },
  async created() {
    await this.getUsers(this.period);
    this.getUsersSum(this.period);
  },
  methods: {
    ...mapActions(["getUsers", "createActFromBank", "sendPayment", "addUser", "userDelete", "userEditStore", "getUsersSum"]),
    sumVal(r, c, val) {
      return val;
    },
    select(r, index) {
      const sel = this.$refs["table0"][0].selection.concat(
        this.$refs["table1"][0].selection
      );
      console.log(this.$store.state);
      this.$store.state.s = sel;
    },
    frm(r, c, v) {
      return 1;
    },
    fileUploaded(e) {
      this.add.file = e.path;
    },
    userEdit(id) {
      const user = this.$store.state.users.find(u => u.id == id);
      this.edit = user;
      this.dialogEdit = true;
    },
  },
  computed: {
    ...mapGetters(["loading", "users", "saving", "users_sum"]),
    filteredUsers() {
      return (index) => this.users.filter((u) => u.group_id == index);
    },
    login() {
      return this.add.fio ? slugify(this.add.fio.split(" ")[0]) : this.add.fio;
    },
  },
};
</script>

<style>
.usersTable {
  width: 100%;
}

.users {
  padding: 40px;
}

.el-upload,
.el-upload-dragger {
  width: 100%;
}
</style>
